<!-- System: STA
    Purpose: Projects create a single card in assign
                  projects modal for user selection
                  and de-selection.
-->
<template>
  <v-container>
    <v-row class="white float-left row m-0 memberClass mt-2 mb-1">
      <!-- Project Name -->
      <v-col
        cols="12"
        :class="{
          green: project.is_assigned,
          grey: project.is_not_my_project,
          orange: project.is_invited,
        }"
        class="float-left text-center p-2 pt-2"
      >
        <p
          :title="project.project.project_name"
          class="empNameLong text-capitalize"
        >
          {{ project_name }}
        </p>
      </v-col>
      <!-- Project Image -->
      <v-row class="w-100 float-left p-2 d-flex justify-center">
        <v-col
          cols="10"
          class="mt-3 d-flex justify-center"
          style="height: 150px"
        >
          <img
            :src="`${base_url}storage/${logo}`"
            @error="
              $event.target.src = require('@/assets/images/dashboard_icons/no_image.svg')
            "
            alt="Thumbnail"
            class="member-image m-auto d-block"
            fluid
            thumbnail
          />
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
import { baseURL } from "@/assets/js/urls";

export default {
  name: "Project",
  props: ["project"],

  computed: {
    is_selected() {
      if (this.selected_members) {
        return !!this.selected_members.find(
          (member) => member.id === this.member.id
        );
      }
    },
    /**
     * Returns the base url
     * of api
     */
    base_url() {
      return baseURL.API_URL;
    },
    /**
     * Returns the logo of project
     */
    logo() {
      let project = this.$store.state.custom.team_members_projects_list.find(
        (project) => project.id === this.project.project.id
      );
      return project && project.logo ? project.logo : null;
    },
    /**
     * Returns the project
     * name in reduced form
     */
    project_name() {
      return this.project.project.project_name.length > 12
        ? this.project.project.project_name
            .match(/\b(\w)/g)
            .join(" ")
            .toUpperCase()
            .slice(0, -1)
            .concat(
              " " + this.project.project.project_name.split(" ").slice(-1)
            )
        : this.project.project.project_name;
    },
  },
};
</script>

<style scoped>
.member-image {
  height: 100px;
  width: 110px;
}

.empNameLong {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  font-size: 14px;
}

.memberClass {
  height: 155px;
  width: 143px;
  box-shadow: inset 0 1px 3px 0 rgba(147, 147, 147, 0.17),
    0 0 10px 0 rgba(214, 214, 214, 0.47);
  border-radius: 3px;
}

@media (max-width: 430px) {
  .memberClass {
    width: 130px !important;
  }
}

@media (max-width: 320px) {
  .memberClass {
    width: 143px !important;
  }
}

.img-thumbnail {
  padding: unset !important;
  background-color: unset !important;
  border: unset !important;
  border-radius: 50%;
  width: 86px;
  height: 84px;
}
</style>
